const mindfulnessTraining = {
  quote1:
    '<p>“Look at the sparrows; they do not know what they will do in the next moment. Let us literally live from moment to moment.”</p><p>MAHATMA GANDHI</p>',
  overview:
    'When we make that first call to seek help from a therapist, there is one thing that we all have in common: we are suffering and want to feel better. Our suffering comes in a myriad of forms, from sadness and despair, to stress and anxiety, to relational conflict and confusion. Whatever the case, our experience of what is happening in our lives is causing distress, and we hope to change it.<br/><br/>Whatever the therapeutic approach, learning to live in the present in a more joyful way is the ultimate goal. We are often unaware of how much time we spend preoccupied with the past, reviewing distressing events that we cannot change, or projecting into the future, mentally manipulating things over which we have no control. We can be especially prone to getting stuck in the past or the future when our current life experience is difficult, and we want to run from our discomfort rather than face it. The more we run, resisting the call to examine or be present with our discomfort, the greater the hold it has over us. Choosing, whether consciously or not, to AVOID the present moment increases our degree of suffering.<br/><br/>So how do we change these mental habits, and find our way back? And how can we trust that learning to open up to what is happening in the moment, even when it is uncomfortable, is a better way?',
  details:
    'Mindfulness is a simple, gentle approach that reminds us of the sweetness and relief that can be found in embracing the present. We learn how to slow down and come home to ourselves, by choosing to pay attention to what is happening right here, right now. Mindfulness is the opposite of running away or ignoring or distracting ourselves. It is intentionally focusing our awareness on what is happening inside–our thoughts, feelings, and bodily sensations. Instead of trying to control or manage what we are experiencing, we learn to observe, with an allowing and welcoming attitude, without judging, criticizing or trying to change ourselves. As we start to become more accepting and comfortable with our interior landscape, we start to see and feel things differently.<br/><br/>Mindfulness is based in a daily, simple meditation practice. Although mindfulness can be viewed as a larger approach to living with greater awareness, it starts with a commitment to sitting quietly and paying attention for a few minutes every day.<br/><br/>Thus begins the process of getting to know ourselves—our whole selves—and how we work. The awareness that arises during our mindfulness practice will over time permeate every other area of our life until living with awareness, tuning in instead of tuning out, becomes our second nature.<br/><br/>The best way to know whether mindfulness will work for you is to give it a try.',
};

export default mindfulnessTraining;
