import React from 'react';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import data from '../../../../data/mindfulnessTraining';

import styles from './mindfulness.module.scss';

const Mindfulness = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498615707/mindfulness.jpg';
  const image1 = 'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498532486/birds.jpg';

  return (
    <main>
      <PageHeader pageCategory="Specialties" pageName="Mindfulness" headerImage={background} />

      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.overview }} />
      </div>
      <QuoteImage image={image1} maxWidth={510} quote={data.quote1} />
      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.details }} />
      </div>
    </main>
  );
};

export default Mindfulness;
