import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Mindfulness from '../../components/Specialties/components/Mindfulness';

export default () => (
  <React.Fragment>
    <SEO
      title="Mindfulness Training in Provo, UT | Mindset Family Therapy"
      description="Mindfulness is a simple, gentle approach that reminds us of the sweetness and relief that can be found in embracing the present. We learn how to slow down and come home to ourselves, by choosing to pay attention to what is happening right here, right now."
      image="https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498532486/birds.jpg"
      pathname="/specialties/mindfulness"
      article
    />
    <Layout>
      <Mindfulness />
    </Layout>
  </React.Fragment>
);
