import React from 'react';
import PropTypes from 'prop-types';
import styles from './quoteImage.module.scss';

const QuoteImage = ({ image, quote, maxWidth }) => {
  const style = {
    background: `url(${image}) no-repeat center center`,
    backgroundSize: 'cover',
    width: '100%',
    height: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={style}>
      {quote && (
        <div
          style={{ maxWidth }}
          className={styles.quoteContainer}
          dangerouslySetInnerHTML={{ __html: quote }}
        />
      )}
    </div>
  );
};

QuoteImage.defaultProps = {
  quote: null,
  maxWidth: null,
};

QuoteImage.propTypes = {
  image: PropTypes.string.isRequired,
  quote: PropTypes.string,
  maxWidth: PropTypes.number,
};

export default QuoteImage;
